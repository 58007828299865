import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { AllWatchers } from "./__generated__/AllWatchers";

const GET_WATCHERS = gql`
    query AllWatchers {
        allWatchers {
            edges {
                node {
                    _id
                    name
                    url
                }
            }
        }
    }
`;

export default function useWatchers() {
    const { loading, error, data } = useQuery<AllWatchers>(GET_WATCHERS);

    const watchers =
        data?.allWatchers?.edges?.map(({ node }: { node: any }) => node) ?? [];

    return { loading, watchers, error };
}
