import React from "react";
import { AuditRun_auditRunById_opportunities } from "../__generated__/AuditRun";
import ScoreCircle from "src/components/ScoreCircle";
import ReactMarkdown from "react-markdown";
import OpportunityTable from "./OpportunityTable";

type Props = {
    opportunity: AuditRun_auditRunById_opportunities;
};
export default function OpportunityRow({ opportunity }: Props) {
    return (
        <div className="mb-16">
            <div className="flex p-2">
                <div className="pr-2 flex items-center">
                    {opportunity.score && (
                        <ScoreCircle score={opportunity.score} />
                    )}
                </div>

                {opportunity.description && (
                    <ReactMarkdown
                        renderers={{
                            link: props => (
                                <a {...props} className="text-blue-500" />
                            )
                        }}
                        source={opportunity.description}
                    />
                )}
            </div>
            <OpportunityTable opportunity={opportunity} />
        </div>
    );
}
