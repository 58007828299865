import { median } from "simple-statistics";
import { Score } from "src/types";

export function getMedianScore(scores: Score[]): number {
    if (scores.length == 0) return 0;
    const scoreValues = scores
        .filter(score => score.value)
        .map(score => score.value) as number[];

    return median(scoreValues);
}
