export function getScoreColor(score: number) {
    if (score > 0.89) {
        return "green";
    }

    if (score > 0.5) {
        return "yellow";
    }

    if (score >= 0) {
        return "red";
    }
}

export function toArray(object: { [key: string]: any }) {
    const keys = Object.keys(object);

    return keys.map((key: string) => object[key]);
}

const strings: { [key: string]: string } = {
    networkServerLatency: "Response Time",
    firstContentfulPaint: "First Contentful Paint",
    firstMeaningfulPaint: "First Meaningful Paint",
    speedIndex: "Speed Index",
    totalBlockingTime: "Total Blocking time",
    interactive: "Time to Interactive",
    bootupTime: "JS Parse & Compile",
    performance: "Performance",
    pwa: "PWA",
    accessibility: "Accessibility",
    bestPractices: "Best Practices",
    seo: "Seo"
};

export function getText(key: string) {
    return strings[key] ?? "";
}

type StringTMap<T> = { [key: string]: T };

export function indexArrayBy<T extends StringTMap<any>>(
    array: T[],
    key: string
) {
    return array.reduce((acc, cur) => {
        const keyValue = cur[key];
        acc[keyValue] = cur;
        return acc;
    }, {} as StringTMap<T>);
}

// from http://scratch99.com/web-development/javascript/convert-bytes-to-mb-kb/
export function bytesToSize(bytes: number) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "n/a";
    var i = parseInt(String(Math.floor(Math.log(bytes) / Math.log(1024))));
    if (i == 0) return bytes + " " + sizes[i];
    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
}
