import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { AuditRun } from "./__generated__/AuditRun";

const GET_AUDIT_RUN = gql`
    query AuditRun($id: MongoID!) {
        auditRunById(id: $id) {
            config
            fetchTime
            opportunities {
                id
                description
                score
                details
            }
            categories {
                performance {
                    score
                }
                accessibility {
                    score
                }
                bestPractices {
                    score
                }
                seo {
                    score
                }
                pwa {
                    score
                }
            }
            audits {
                screenshotThumbnails {
                    details
                }
                networkServerLatency {
                    displayValue
                    score
                }
                firstContentfulPaint {
                    displayValue
                    score
                }
                firstMeaningfulPaint {
                    displayValue
                    score
                }
                speedIndex {
                    displayValue
                    score
                }
                totalBlockingTime {
                    displayValue
                    score
                }
                interactive {
                    score
                    displayValue
                }
                bootupTime {
                    score
                    displayValue
                }
            }
        }
    }
`;

export default function useAuditRun(id: string) {
    const { loading, error, data } = useQuery<AuditRun>(GET_AUDIT_RUN, {
        variables: { id },
        fetchPolicy: "cache-and-network"
    });
    const auditRun = data?.auditRunById;

    return { loading, auditRun, error };
}
