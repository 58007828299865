import React, { PropsWithChildren } from "react";
import { NavLink as ReactRouterNavLink, useLocation } from "react-router-dom";

type Props = PropsWithChildren<{
    to: string;
    activeClassName?: string;
    inactiveClassName?: string;
    className?: string;
}>;

export default function NavLink({
    children,
    to,
    activeClassName,
    inactiveClassName,
    className
}: Props) {
    const location = useLocation();
    const isActive = location.pathname.startsWith(to);
    const mergedClassName = [
        isActive ? activeClassName : inactiveClassName,
        className
    ].join(" ");

    return (
        <ReactRouterNavLink to={to} className={mergedClassName}>
            {children}
        </ReactRouterNavLink>
    );
}
