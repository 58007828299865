import firebase from "firebase/app";
import "firebase/firebase-firestore";
import "firebase/firebase-auth";

const firebaseConfig = {
    apiKey: "AIzaSyA5Avpl7CCEUms52nU-C2Ud0fo48IpUmrs",
    authDomain: "lightfire-8b345.firebaseapp.com",
    databaseURL: "https://lightfire-8b345.firebaseio.com",
    projectId: "lightfire-8b345",
    storageBucket: "lightfire-8b345.appspot.com",
    messagingSenderId: "94938620464",
    appId: "1:94938620464:web:d2d59337cb13f1889303f5"
};

firebase.initializeApp(firebaseConfig);

export default firebase;

export function getAll(querySnapshot: firebase.firestore.QuerySnapshot) {
    let results: firebase.firestore.QueryDocumentSnapshot[] = [];

    querySnapshot.forEach(doc => {
        results.push(doc);
    });

    return results;
}
