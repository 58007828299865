import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import Description from "./Description";

type Props = {
    name?: string | null;
    watcherId?: string;
    url?: string | null;
    className?: string;
};

export default function Header({ name, watcherId, url, className }: Props) {
    const [showDescription, setShowDescription] = useState(false);

    return (
        <div className="py-3">
            <div className="flex justify-between">
                <div>
                    <h1 className="text-4xl text-gray-600 font-thin">{name}</h1>
                    {url && (
                        <a
                            target="_blank"
                            className="text-gray-700 underline"
                            href={url}
                        >
                            {url}
                        </a>
                    )}
                </div>
                <button
                    className="text-teal-700 hover:text-teal-900 text-3xl focus:outline-none"
                    onClick={() => setShowDescription(v => !v)}
                >
                    {showDescription && <FontAwesomeIcon icon={faTimes} />}
                    {!showDescription && (
                        <FontAwesomeIcon icon={faInfoCircle} />
                    )}
                </button>
            </div>
            {showDescription && <Description watcherId={watcherId} />}
        </div>
    );
}
