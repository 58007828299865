import React from "react";
import { Score } from "src/types";
import { line, curveMonotoneX } from "d3";

type Props = {
    color?: string;
    scores: Score[];
    xScale(value: any): number;
    yScale(value: any): number;
};

export default function Line({ color, scores, xScale, yScale }: Props) {
    const lineFn = line<[Date, number]>()
        .x(v => {
            // console.log(v, xScale(v[0]));
            return xScale(v[0]);
        })
        .y(v => {
            // console.log(v, xScale(v[0]));
            return yScale(v[1]);
        })
        .curve(curveMonotoneX);

    const mappedScores = scores.map<[Date, number]>(({ date, value }) => [
        date.toJSDate(),
        value ?? 0
    ]);

    const linePath = lineFn(mappedScores);

    return (
        <path
            fill="none"
            className={`text-${color}-500`}
            strokeWidth="2"
            stroke="currentColor"
            d={linePath ?? "  "}
        />
    );
}
