import React from "react";
import ScoreCircle from "src/components/ScoreCircle";
import { getText } from "src/helpers";
import { CategoryType } from "src/types";
import { AuditRun_auditRunById_categories } from "../__generated__/AuditRun";

type Props = {
    categories: AuditRun_auditRunById_categories;
};

const categoryTpes: CategoryType[] = [
    "performance",
    "pwa",
    "accessibility",
    "bestPractices",
    "seo"
];

export default function MainScores({ categories }: Props) {
    return (
        <div className="flex flex-wrap justify-between">
            {categoryTpes.map(categoryType => {
                return (
                    <div
                        className="px-4 py-4 w-28 flex flex-col justify-center items-center"
                        key={categoryType}
                    >
                        <ScoreCircle
                            size={2}
                            score={categories?.[categoryType]?.score ?? 0}
                        />
                        <span className="text-sm text-gray-900">
                            {getText(categoryType)}
                        </span>
                    </div>
                );
            })}
        </div>
    );
}
