import { useMemo } from "react";
import { scaleLinear, scaleTime } from "d3";
import { Score } from "src/types";

type Args = {
    height: number;
    width: number;
    scores: Score[];
};

export default function useScales({ height, width, scores }: Args) {
    const y = useMemo(
        () =>
            scaleLinear()
                .domain([0, 100])
                .range([height, 0]),
        [height]
    );

    const x = useMemo(() => {
        const firstDate = scores?.[0]?.date?.toJSDate();
        const lastScore = [...scores].pop();
        const lastDate = lastScore?.date?.toJSDate();
        const extent = firstDate && lastDate ? [firstDate, lastDate] : [];
        return scaleTime()
            .range([0, width])
            .domain(extent);
    }, [width, height, scores]);

    return [x, y];
}
