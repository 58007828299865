import { useCallback } from "react";
import useAuthState from "src/hooks/useAuthState";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { CreateWatcher_createWatcher } from "./__generated__/CreateWatcher";

const CREATE_WATCHER = gql`
    mutation CreateWatcher($name: String!, $url: String!) {
        createWatcher(name: $name, url: $url) {
            _id
            name
            url
        }
    }
`;

type Args = {
    onCreate(watcher: CreateWatcher_createWatcher): void;
};

type FormData = {
    name: string;
    url: string;
};

export default function useOnSubmit({ onCreate }: Args) {
    const [user] = useAuthState();
    const [createWatcher] = useMutation(CREATE_WATCHER);
    return useCallback(
        async ({ name, url }: FormData) => {
            const result = await createWatcher({ variables: { name, url } });
            onCreate(result?.data?.createWatcher);
        },
        [onCreate]
    );
}
