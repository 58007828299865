import React from "react";
import { DateTime } from "luxon";
import ScreenshotThumbnails from "src/Watcher/ScreenshotThumbnails";
import Metrics from "./Metrics";
import AuditRunInfo from "./AuditRunInfo";
import useAuditRun from "../useAuditRun";
import Card from "src/components/Card";
import MainScores from "./MainScores";
import OpportunityRow from "./OpportunityRow";

type Props = {
    auditRunId: string;
    watcherId: string;
};

export default function AuditRunCard({ auditRunId, watcherId }: Props) {
    const { auditRun } = useAuditRun(auditRunId);
    const fetchTime = auditRun?.fetchTime;

    if (!auditRun) {
        return null;
    }

    const opportunities =
        auditRun?.opportunities
            ?.slice()
            ?.sort((a, b) => (a?.score ?? 0) - (b?.score ?? 0)) ?? [];

    return (
        <Card>
            <Card.Header backPath={`/watchers/${watcherId}`}>
                <div className="font-thin text-xl text-center">
                    {fetchTime && DateTime.fromISO(fetchTime).toFormat("FF")}
                </div>
                <AuditRunInfo auditRun={auditRun} />
            </Card.Header>

            {auditRun.categories && (
                <MainScores categories={auditRun.categories} />
            )}

            {auditRun.audits?.screenshotThumbnails?.details && (
                <ScreenshotThumbnails
                    screenshotThumbnails={
                        auditRun.audits.screenshotThumbnails.details.items
                    }
                />
            )}

            {auditRun.audits && <Metrics audits={auditRun.audits} />}

            {opportunities.map((opportunity, index) => {
                if (!opportunity) return null;
                return (
                    <OpportunityRow
                        opportunity={opportunity}
                        key={`${auditRunId}_${index}`}
                    />
                );
            })}
        </Card>
    );
}
