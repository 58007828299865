import React from "react";
import { NavLink } from "react-router-dom";
import useWatchers from "./useWatchers";

export default function WatcherList() {
    const { watchers } = useWatchers();

    return (
        <div className="max-w-5xl px-2 md:px-0 mx-auto">
            <div className="flex justify-between items-center">
                <h1 className="text-3xl font-light">Your watchers</h1>
                <NavLink
                    to="/watchers/new"
                    className="text-teal-500 border border-teal-500 rounded px-3 py-2 hover:bg-teal-500 hover:text-gray-200"
                >
                    New Watcher
                </NavLink>
            </div>
            <div>
                {watchers?.map((watcher: any) => (
                    <NavLink
                        className="block my-4 bg-white rounded p-2 shadow hover:bg-teal-100"
                        to={`/watchers/${watcher._id}`}
                        key={watcher._id}
                    >
                        {watcher.name ?? watcher._id}
                    </NavLink>
                ))}
            </div>
        </div>
    );
}
