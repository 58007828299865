import React from "react";
import { CategoryType, Score } from "src/types";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { Watcher_watcherById_allAuditRuns_edges_node } from "./__generated__/Watcher";

type Props = {
    auditRuns: Watcher_watcherById_allAuditRuns_edges_node[];
    watcherId: string;
};

export default function AuditRunList({ auditRuns, watcherId }: Props) {
    return (
        <div className="bg-white rounded shadow-md">
            {auditRuns
                .slice()
                .reverse()
                .map((auditRun, i) => {
                    const fetchTime = DateTime.fromISO(auditRun.fetchTime);
                    const isLast = i !== auditRuns.length - 1;
                    const dateString = fetchTime.toFormat(
                        "LLL dd, yyyy 'at' HH:mm"
                    );

                    return (
                        <Link
                            key={auditRun._id}
                            to={`/watchers/${watcherId}/audit-runs/${auditRun._id}`}
                            className={`p-2 ${
                                isLast ? "border-b" : ""
                            } flex hover:bg-gray-900 hover:text-white text-gray-700 justify-between items-center border-gray-400`}
                        >
                            <span className="font-thin text-sm ">
                                {dateString}
                            </span>
                        </Link>
                    );
                })}
        </div>
    );
}
