import React from "react";
import ScoreCircle from "src/components/ScoreCircle";

type Props = {
    title: string;
    score?: number | null;
    value: string | number;
    className?: string;
};

export default function Metric({ title, score, value, className }: Props) {
    return (
        <div className={`text-gray-800 mt-5 px-4 ${className}`}>
            <div className="text mb-2 font-light">{title}</div>
            <div className="flex items-center">
                {score != null && (
                    <ScoreCircle className="mr-3" score={score} />
                )}
                <span className="text-4xl">{value}</span>
            </div>
        </div>
    );
}
