import React from "react";
import Loader from "react-loader-spinner";
import tailwindConfig from "src/tailwindConfig";

export default function LoadingIndicator() {
    return (
        <div className="flex h-full w-full items-center justify-center">
            <Loader
                type="Puff"
                color={tailwindConfig.theme.colors.teal[500]}
                height={100}
                width={100}
            />
        </div>
    );
}
