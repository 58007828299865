import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

type Props = {
    onClick(): void;
};

export default function SignOutButton({ onClick }: Props) {
    return (
        <button
            className="rounded-full h-12 w-12 hover:bg-gray-600 hover:text-white"
            onClick={onClick}
        >
            <FontAwesomeIcon icon={faSignOutAlt} />
        </button>
    );
}
