import React from "react";

type Props = {
    src?: string | null;
    alt?: string | null;
};

export default function UserImage({ src, alt }: Props) {
    return (
        <img
            className="rounded-full border-2 h-10 w-10 shadow"
            src={src ?? ""}
            alt={alt ?? ""}
        />
    );
}
