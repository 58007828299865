module.exports = {
    theme: {
        extend: {},
        linearGradients: theme => ({
            colors: theme("colors")
        })
    },
    variants: {},
    plugins: [require("tailwindcss-gradients")()]
};
