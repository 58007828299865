import React from "react";
import { getScoreColor } from "src/helpers";

type Props = {
    score: number;
    className?: string;
    size?: 1 | 2;
};

export default function ScoreCircle({ score, className, size }: Props) {
    const color = getScoreColor(score);
    let sizeClasses = "h-10 w-10";
    if (size == 2) {
        sizeClasses = "h-10 md:h-16 w-10 md:w-16 md:text-2xl";
    }
    return (
        <div
            className={`text-${color}-700 border-2 ${sizeClasses} bg-${color}-100 flex items-center justify-center rounded-full border-${color}-700 ${className}`}
        >
            {(score * 100).toFixed(0)}
        </div>
    );
}
