import React from "react";
import Header from "./Header";

type Props = {
    children: React.ReactNode;
    className?: string;
};

Card.Header = Header;

export default function Card({ children, className }: Props) {
    return (
        <div className={`bg-white rounded shadow-md ${className}`}>
            {children}
        </div>
    );
}
