import React, { useState } from "react";
import firebase from "src/firebase";
import useOnSubmit from "./useOnSubmit";
import { CreateWatcher_createWatcher } from "./__generated__/CreateWatcher";
import { useHistory } from "react-router";

export default function WatcherForm() {
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const history = useHistory();

    function onCreate(watcher: CreateWatcher_createWatcher) {
        history.push(`/watchers/${watcher._id}`);
    }

    const onSubmit = useOnSubmit({ onCreate });

    return (
        <div className="bg-white rounded shadow p-5 max-w-xl mx-auto">
            <h1 className="text-2xl text-center">New Page Watcher</h1>
            <form
                className="mt-5"
                onSubmit={e => {
                    e.preventDefault();
                    onSubmit({ name, url });
                }}
            >
                <label htmlFor="name">Name</label>
                <input
                    className="w-full border border-gray-500 rounded px-3 py-2 mr-2 mb-4"
                    value={name}
                    placeholder="e.g. Landing Page"
                    id="name"
                    required
                    onChange={e => setName(e.target.value)}
                />

                <label htmlFor="name">Url</label>
                <input
                    className="w-full border border-gray-500 rounded px-3 py-2 mr-2"
                    value={url}
                    type="url"
                    placeholder="Url"
                    id="url"
                    required
                    pattern="https://.+"
                    onChange={e => setUrl(e.target.value)}
                />

                <div className="flex justify-end mt-5">
                    <button
                        className="bg-teal-500 text-white px-3 py-2 rounded"
                        type="submit"
                    >
                        Create Watcher
                    </button>
                </div>
            </form>
        </div>
    );
}
