import React from "react";

type Props = { watcherId?: string };

export default function Description({ watcherId }: Props) {
    return (
        <div>
            <span className="font-bold mr-2">Webhook for Netlify:</span>
            <div>https://lightfire.geraldurschitz.com/{watcherId}</div>
            <div className="mt-2 text-gray-700 text-sm">
                <ol className="list-decimal list-inside">
                    <li>
                        Go to Build & deploy > Deploy notifications and click on
                        "Add notification".
                    </li>
                    <li className="mt-2">
                        Add a new Outgoing Webhook with "Deploy succeeded" as
                        the Event to listen for.
                    </li>
                    <li className="mt-2">
                        Copy and paste the URL above into the field "URL to
                        notify".
                    </li>
                </ol>
            </div>
        </div>
    );
}
