import { CategoryType } from "src/types";
import { Watcher_watcherById_allAuditRuns_edges_node } from "./__generated__/Watcher";

export function getScorePercent(
    auditRun: Watcher_watcherById_allAuditRuns_edges_node,
    category: CategoryType
): number | null {
    let score = auditRun?.categories?.[category]?.score;
    if (!score) return null;
    return Number(score) * 100;
}
