import React from "react";
import { Score } from "src/types";

type Props = {
    color?: string;
    scores: Score[];
    highlightedAuditRunId?: string | null;
    selectedAuditRunId?: string | null;
    xScale(value: any): number;
    yScale(value: any): number;
};

export default function ScorePoints({
    scores,
    color,
    xScale,
    yScale,
    highlightedAuditRunId,
    selectedAuditRunId
}: Props) {
    return (
        <>
            {scores.map((score, i) => {
                let radius = 3;
                let className = `text-${color}-500`;

                const isSelected = score.auditRunId === selectedAuditRunId;
                const isHighlighted =
                    score.auditRunId === highlightedAuditRunId;
                radius = isSelected ? 3 : radius;

                const buildId = score.auditRun.config?.payload?.build_id;

                if (buildId) {
                    className = "text-blue-900";
                }

                return (
                    <g key={i}>
                        <rect
                            width="1"
                            className="text-gray-700"
                            transform={`translate(${xScale(score.date)},0)`}
                            fill={isHighlighted ? "currentColor" : "none"}
                            height={yScale(1)}
                        />
                        <rect
                            width="1"
                            className="text-gray-900"
                            transform={`translate(${xScale(score.date)},0)`}
                            fill={isSelected ? "currentColor" : "none"}
                            height={yScale(1)}
                        />

                        <text
                            textAnchor="middle"
                            opacity={isSelected ? 1 : 0}
                            className="text-sm"
                            x={xScale(score.date)}
                            y={yScale(1) + 16}
                        >
                            {score.value?.toFixed(0)}
                        </text>
                        <text
                            textAnchor="middle"
                            className="text-gray-700 text-sm"
                            fill="currentColor"
                            opacity={isHighlighted ? 1 : 0}
                            x={xScale(score.date)}
                            y={yScale(1) + 16}
                        >
                            {score.value?.toFixed(0)}
                        </text>
                        <circle
                            className={className}
                            cx={xScale(score.date)}
                            cy={yScale(score.value ?? 0)}
                            fill={isSelected ? "white" : "currentColor"}
                            strokeWidth="2"
                            stroke={isSelected ? "currentColor" : "none"}
                            r={radius}
                        />
                    </g>
                );
            })}
        </>
    );
}
