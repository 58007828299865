import React from "react";
import { toArray } from "src/helpers";

type Props = {
    screenshotThumbnails: { data: string }[];
};

export default function ScreenshotThumbnails({ screenshotThumbnails }: Props) {
    return (
        <div className="flex">
            {toArray(screenshotThumbnails).map(({ data }, i: number) => (
                <img
                    className="block border"
                    style={{ width: "10%" }}
                    key={i}
                    src={data}
                />
            ))}
        </div>
    );
}
