import React from "react";
import { AuditRun_auditRunById } from "../__generated__/AuditRun";

type Props = {
    auditRun: AuditRun_auditRunById;
};

export default function AuditRunInfo({ auditRun }: Props) {
    const commitMessage = auditRun?.config?.payload?.title;

    return (
        <div className="h-6">
            <div className="text-center text-sm font-thin text-gray-600">
                {commitMessage ? commitMessage : "scheduled audit run"}
            </div>
        </div>
    );
}
