import { useHistory } from "react-router-dom";
import { useLayoutEffect } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Watcher } from "./__generated__/Watcher";

const GET_WATCHER = gql`
    query Watcher($id: MongoID!) {
        watcherById(id: $id) {
            _id
            name
            url
            allAuditRuns(last: 30, sort: _ID_ASC) {
                edges {
                    node {
                        _id
                        fetchTime
                        config
                        categories {
                            performance {
                                score
                            }
                            accessibility {
                                score
                            }
                            bestPractices {
                                score
                            }
                            seo {
                                score
                            }
                            pwa {
                                score
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default function useWatcher(id: string) {
    const history = useHistory();
    const { loading, error, data } = useQuery<Watcher>(GET_WATCHER, {
        variables: { id }
    });
    const watcher = data?.watcherById;

    useLayoutEffect(() => {
        if (!watcher && !loading) history.push("/watchers");
    }, [watcher, loading, history]);

    return { loading, watcher, error };
}
