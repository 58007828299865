import React from "react";
import { AuditRun_auditRunById_audits } from "../__generated__/AuditRun";
import Metric from "./Metric";
import { getText } from "src/helpers";

type AuditMetricKeys = keyof Omit<
    AuditRun_auditRunById_audits,
    "__typename" | "screenshotThumbnails"
>;

const auditMetricKeys: AuditMetricKeys[] = [
    "networkServerLatency",
    "firstContentfulPaint",
    "firstMeaningfulPaint",
    "speedIndex",
    "totalBlockingTime",
    "interactive",
    "bootupTime"
];

type Props = {
    audits: AuditRun_auditRunById_audits;
};

export default function AuditMetrics({ audits }: Props) {
    return (
        <div className="flex flex-wrap border-b border-gray-300 pb-3">
            {auditMetricKeys.map(metric => {
                let formattedDisplayValue = audits?.[metric]?.displayValue;

                if (formattedDisplayValue) {
                    formattedDisplayValue = formattedDisplayValue.replace(
                        ",",
                        ""
                    );
                }

                return (
                    <Metric
                        key={metric}
                        score={audits?.[metric]?.score}
                        value={formattedDisplayValue ?? ""}
                        title={getText(metric)}
                    />
                );
            })}
        </div>
    );
}
