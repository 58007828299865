import React from "react";
import { AuditRun_auditRunById_opportunities } from "../__generated__/AuditRun";
import { indexArrayBy, bytesToSize } from "src/helpers";

type Props = {
    opportunity: AuditRun_auditRunById_opportunities;
};

type Heading = {
    key: string;
    label: string;
    valueType: string;
};

export default function OpportunityTable({ opportunity }: Props) {
    if (!opportunity.details.headings) return null;

    const headings = Object.values<Heading>(
        opportunity.details.headings
    ).filter(h => h.label != "");
    const items = Object.values(opportunity.details.items);
    const indexedHeadings = indexArrayBy(headings, "key");
    const keys = headings.map(heading => heading.key);

    return (
        <table className="table-auto text-sm w-full">
            <thead>
                <tr>
                    {headings.map((heading: any, index) => (
                        <th className="text-left p-2" key={index}>
                            {heading.label}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {items.map((item: any, i) => (
                    <tr
                        className={`${
                            i % 2 === 0 ? "bg-gray-100" : ""
                        } border border-gray-300`}
                        key={i}
                    >
                        {keys.map(k => {
                            let renderedItemValue = item[k];
                            const valueType = indexedHeadings[k].valueType;
                            if (valueType === "bytes") {
                                renderedItemValue = (
                                    <span className="whitespace-no-wrap">
                                        {bytesToSize(renderedItemValue)}
                                    </span>
                                );
                            } else if (valueType === "timespanMs") {
                                renderedItemValue = (
                                    <span className="whitespace-no-wrap">
                                        {renderedItemValue} ms
                                    </span>
                                );
                            } else if (valueType === "url") {
                                renderedItemValue = (
                                    <a
                                        className="text-blue-700 underline"
                                        href={renderedItemValue}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {renderedItemValue}
                                    </a>
                                );
                            }

                            return (
                                <td
                                    className={`
                                        ${
                                            k === "url"
                                                ? "break-all w-full"
                                                : ""
                                        } p-2
                                    `}
                                    key={k}
                                >
                                    {renderedItemValue}
                                </td>
                            );
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}
