import React from "react";
import firebase from "src/firebase";
import useAuthState from "src/hooks/useAuthState";
import NavLink from "src/components/NavLink";
import UserImage from "./UserImage";
import SignOutButton from "./SignOutButton";

export default function Header() {
    const [user] = useAuthState();

    return (
        <nav className="px-2 md:px-0text-gray-300 w-full z-30">
            <div className="container w-full mx-auto flex justify-between items-center h-16">
                <div className="items-center flex">
                    <NavLink
                        to="/watchers"
                        activeClassName="text-gray-900 border-b border-gray-900"
                        inactiveClassName="text-gray-900"
                        className="h-full flex items-center text-center"
                    >
                        Watchers
                    </NavLink>
                </div>
                <div className="flex items-center">
                    <UserImage src={user?.photoURL} alt={user?.displayName} />
                    <div className="ml-4 mr-2 hidden md:inline">
                        {user?.displayName}
                    </div>
                    <SignOutButton onClick={() => firebase.auth().signOut()} />
                </div>
            </div>
        </nav>
    );
}
