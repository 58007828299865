import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

type Props = {
    backPath?: string | null;
    children: React.ReactNode;
};
export default function Header({ backPath, children }: Props) {
    return (
        <div className="p-2 relative border-b border-gray-300">
            {backPath && (
                <Link
                    className="text-gray-800 absolute left-0 px-2"
                    to={backPath}
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                </Link>
            )}

            {children}
        </div>
    );
}
