import React, { Suspense } from "react";
import Container from "src/components/Container";
import Login from "src/Login";
import Header from "./Header";
import WatcherForm from "src/WatcherForm";
import { Route, Switch, useHistory, Redirect } from "react-router-dom";
import useAuthState from "src/hooks/useAuthState";
import LoadingIndicator from "./LoadingIndicator";
import WatcherList from "src/WatcherList";
import Watcher from "src/Watcher";
import { ApolloProvider } from "@apollo/react-hooks";
import client from "src/ApolloClient";

function App() {
    const [user, initializing] = useAuthState();

    if (initializing) return <LoadingIndicator />;
    if (!user) return <Login />;

    return (
        <ApolloProvider client={client}>
            <Header />
            <Container className="h-full">
                <Suspense fallback={<LoadingIndicator />}>
                    <Switch>
                        <Route path="/watchers/new">
                            <WatcherForm />
                        </Route>
                        <Route exact path="/watchers">
                            <WatcherList />
                        </Route>
                        <Route
                            path="/watchers/:watcherId"
                            render={({ match }) => (
                                <Watcher watcherId={match.params.watcherId} />
                            )}
                        />
                        <Route>
                            <Redirect to="/watchers" />
                        </Route>
                    </Switch>
                </Suspense>
            </Container>
        </ApolloProvider>
    );
}

export default App;
