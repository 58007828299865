import React from "react";
import useWatcher from "./useWatcher";
import Header from "./Header";
import { CategoryType, Score } from "src/types";
import { getScorePercent } from "./helpers";
import { DateTime } from "luxon";
import ScoreChart from "src/components/ScoreChart";
import AuditRunCard from "./AuditRunCard";
import AuditRunList from "./AuditRunList";
import { getText } from "src/helpers";
import Card from "src/components/Card";
import useAuditNavigator from "./useAuditNavigator";

type Props = {
    watcherId: string;
};

const categories: CategoryType[] = [
    "performance",
    "bestPractices",
    "accessibility",
    "seo",
    "pwa"
];

export default function Watcher({ watcherId }: Props) {
    const { watcher } = useWatcher(watcherId);
    const auditRuns =
        watcher?.allAuditRuns?.edges?.map(({ node }) => node) ?? [];

    const { selectedAuditRun, selectedAuditRunId } = useAuditNavigator({
        watcherId,
        auditRuns
    });

    const scoresPerCategory: { [key in CategoryType]: Score[] } = {
        performance: [],
        pwa: [],
        accessibility: [],
        bestPractices: [],
        seo: []
    };

    categories.forEach(category => {
        auditRuns
            .filter(d => d.categories)
            .forEach(auditRun => {
                const score = {
                    value: getScorePercent(auditRun, category),
                    date: DateTime.fromISO(auditRun?.fetchTime),
                    auditRunId: auditRun._id,
                    auditRun: auditRun
                };
                scoresPerCategory[category].push(score);
            });
    });

    const keys = Object.keys(scoresPerCategory) as CategoryType[];

    return (
        <div className="px-3 pb-10">
            <Header
                name={watcher?.name}
                watcherId={watcher?._id}
                url={watcher?.url}
                className="mb-4"
            />
            <div className="flex flex-col lg:flex-row items-stretch lg:items-start">
                <Card
                    className={`md:w-1/3 ${
                        selectedAuditRunId ? "hidden lg:block" : ""
                    }`}
                >
                    {keys.map(category => {
                        return (
                            <ScoreChart
                                key={category}
                                className="w-full lg:max-w-lg xl:max-w-2xl mb-2"
                                name={getText(category)}
                                selectedAuditRunId={selectedAuditRunId}
                                watcherId={watcherId}
                                scores={scoresPerCategory[category]}
                            />
                        );
                    })}
                </Card>
                <div className="flex-1 lg:px-2">
                    {selectedAuditRun && (
                        <AuditRunCard
                            watcherId={watcherId}
                            auditRunId={selectedAuditRun._id}
                        />
                    )}
                    {!selectedAuditRun && (
                        <AuditRunList
                            auditRuns={auditRuns}
                            watcherId={watcherId}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
