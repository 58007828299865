import React, { useCallback } from "react";
import firebase from "src/firebase";
import provider from "./provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

export default function Login() {
    const handleLogin = useCallback(async () => {
        const { credential, user } = await firebase
            .auth()
            .signInWithPopup(provider);
    }, []);

    return (
        <div className="h-full w-full flex flex-col items-center justify-center">
            <div className="font-hairline text-6xl mb-1">Lightfire</div>
            <div className="font-thin text-gray-500 text-xl mb-10">
                Easy Lighthouse Analytics
            </div>
            <div className="bg-white rounded shadow max-w-sm w-full p-5">
                <button
                    className="bg-gray-900 text-white px-3 py-2 rounded w-full text-center"
                    onClick={handleLogin}
                >
                    <FontAwesomeIcon className="text-xl" icon={faGithub} />
                    <span className="ml-3">Login with GitHub</span>
                </button>
            </div>
        </div>
    );
}
