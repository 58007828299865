import React from "react";
import tailwindConfig from "src/tailwindConfig";

type Props = {
    height: number;
    color: string;
};

export default function LinearGradient({ height, color }: Props) {
    return (
        <linearGradient
            id={`gradient-${color}`}
            gradientUnits="userSpaceOnUse"
            x1="0"
            y1={height}
            x2="0"
            y2="0"
        >
            <stop
                offset="0%"
                stopColor={tailwindConfig.theme.colors[color]?.[200]}
            ></stop>
            <stop
                offset="50%"
                stopColor={tailwindConfig.theme.colors[color]?.[400]}
            ></stop>
            <stop
                offset="100%"
                stopColor={tailwindConfig.theme.colors[color]?.[500]}
            ></stop>
        </linearGradient>
    );
}
