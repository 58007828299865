import React, { useState } from "react";
import useDimensions from "react-use-dimensions";
import { Score } from "src/types";
import LinearGradient from "./LinearGradient";
import { bisector } from "d3";
import { getScoreColor } from "src/helpers";
import { getMedianScore } from "./helpers";
import ScorePoints from "./ScorePoints";
import Line from "./Line";
import useScales from "./useScales";
import { DateTime } from "luxon";
import ScoreCircle from "../ScoreCircle";
import { useHistory } from "react-router";

type Props = {
    className?: string;
    scores: Score[];
    name: string;
    selectedAuditRunId?: string | null;
    watcherId: string | null;
};

export default function ScoreChart({
    className,
    name,
    scores,
    selectedAuditRunId,
    watcherId
}: Props) {
    const history = useHistory();
    const [ref, dimensions] = useDimensions();

    const [highlightedAuditRunId, setHighlightedAuditRunId] = useState<
        string | null | undefined
    >(null);

    let height = 80;
    let width = dimensions.width;
    const bisectDate = bisector((d: Score) => d.date).left;
    const medianScore = getMedianScore(scores);
    const color = getScoreColor(medianScore / 100);
    const margin = { top: 10, right: 20, bottom: 18, left: 20 };

    const adjustedHeight = height - margin.top - margin.bottom;
    const adjustedWidth = width - margin.left - margin.right;

    const [xScale, yScale] = useScales({
        height: adjustedHeight,
        width: adjustedWidth,
        scores
    });

    return (
        <div
            ref={ref}
            className={[
                className,
                "flex flex-col items-between justify-between relative"
            ].join(" ")}
        >
            <div className="flex items-center justify-between mb-2 p-2">
                <div className="flex">
                    <ScoreCircle score={medianScore / 100} />
                    <h2 className="ml-2 text-xl font-thin">{name}</h2>
                </div>
            </div>
            {width && (
                <svg className="z-10" width={width} height={height}>
                    <g transform={`translate(${margin.left},${margin.top})`}>
                        <Line
                            scores={scores}
                            color={color}
                            xScale={xScale}
                            yScale={yScale}
                        />
                        <ScorePoints
                            color={color}
                            scores={scores}
                            xScale={xScale}
                            yScale={yScale}
                            selectedAuditRunId={selectedAuditRunId}
                            highlightedAuditRunId={highlightedAuditRunId}
                        />
                        <LinearGradient height={height} color="red" />
                        <LinearGradient height={height} color="yellow" />
                        <LinearGradient height={height} color="green" />
                    </g>
                    <rect
                        fill="none"
                        width={width}
                        height={height}
                        className="cursor-pointer"
                        pointerEvents="all"
                        onClick={() => {
                            if (highlightedAuditRunId) {
                                history.push(
                                    `/watchers/${watcherId}/audit-runs/${highlightedAuditRunId}`
                                );
                            }
                        }}
                        onMouseOut={() => setHighlightedAuditRunId(null)}
                        onMouseMove={e => {
                            const mouseX = e.nativeEvent.offsetX - margin.left;
                            const hoveredDate = DateTime.fromJSDate(
                                xScale.invert(mouseX) as Date
                            );
                            const i = bisectDate(scores, hoveredDate, 1);
                            const d0 = scores[i - 1];
                            const d1 = scores[i];
                            if (d0 && d1) {
                                const score =
                                    hoveredDate.diff(d0.date) >
                                    d1.date.diff(hoveredDate)
                                        ? d1
                                        : d0;

                                if (score) {
                                    setHighlightedAuditRunId(score.auditRunId);
                                }
                            }
                        }}
                    />
                </svg>
            )}
        </div>
    );
}
