import { useRouteMatch, useHistory } from "react-router";
import { Watcher_watcherById_allAuditRuns_edges_node } from "./__generated__/Watcher";
import { useEffect } from "react";

type Args = {
    watcherId: string;
    auditRuns: Watcher_watcherById_allAuditRuns_edges_node[];
};

export default function useAuditNavigator({ watcherId, auditRuns }: Args) {
    const history = useHistory();

    const match = useRouteMatch<{ auditRunId?: string }>({
        path: `/watchers/${watcherId}/audit-runs/:auditRunId`
    });
    const selectedAuditRunId = match?.params?.auditRunId;

    let selectedAuditRun;
    let nextAuditRunId: string | null = null;
    let previousAuditRunId: string | null = null;

    const selectedAuditRunIndex = auditRuns.findIndex(
        run => run._id === selectedAuditRunId
    );

    if (selectedAuditRunIndex >= 0) {
        if (selectedAuditRunIndex != 0) {
            previousAuditRunId = auditRuns[selectedAuditRunIndex - 1]._id;
        } else {
            previousAuditRunId = null;
        }

        selectedAuditRun = auditRuns[selectedAuditRunIndex];

        if (selectedAuditRunIndex != auditRuns.length - 1) {
            nextAuditRunId = auditRuns[selectedAuditRunIndex + 1]._id;
        } else {
            nextAuditRunId = null;
        }
    }

    useEffect(() => {
        function handleKeyPress(event: KeyboardEvent) {
            if (event.code === "ArrowLeft" && previousAuditRunId) {
                history.push(
                    `/watchers/${watcherId}/audit-runs/${previousAuditRunId}`
                );
            }

            if (event.code === "ArrowRight" && nextAuditRunId) {
                history.push(
                    `/watchers/${watcherId}/audit-runs/${nextAuditRunId}`
                );
            }
        }

        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [previousAuditRunId, nextAuditRunId]);

    return {
        selectedAuditRun,
        nextAuditRunId,
        previousAuditRunId,
        selectedAuditRunId
    };
}
