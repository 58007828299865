import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import firebase from "src/firebase";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });

const authLink = setContext(async (_, { headers }) => {
    const token = await firebase
        .auth()
        .currentUser?.getIdToken(/* forceRefresh */ true);
    console.log(token);
    return {
        headers: {
            ...headers,
            authorization: token
        }
    };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

export default client;
